.takeScreenshotbuttonDiv {
  margin-top: 8px;
  margin-right: 10px;
  .takeScreenshotButton {
    color: #fff;
    padding: 0;
    display: flex;
    background-image: linear-gradient(-60deg, #ff5858 0%, #f09819 100%);
    border: none;
    cursor: pointer;
    span.icon {
      margin: auto;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(60deg, #b57415 0%, #d84545 100%);
    }
    span.text {
      padding: 9px;
      display: flex;
      white-space: nowrap;
      justify-content: center;
      align-items: center;
    }
  }
}

.screenshot_remove_compare_button_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
