@import '../variables.scss';

// Items hidden by search.
.searchHidden {
  opacity: 0.2;
  transform: scale(0.75);
}

// The wrapper for draggable items. Global because it's referenced by other styles.
:global(.item-drag-container) {
  contain: layout paint style;
  cursor: pointer;
  box-sizing: border-box;
  width: var(--item-size);

  &:hover {
    @include draggable-hover-border;
  }
}

// The top-level item container. Global because it's referenced by other styles.
:global(.item) {
  position: relative;
  contain: layout paint style;
  box-sizing: border-box;
  width: var(--item-size);
  // searchHidden will adjust opacity/transform, this transitions them
  transition: opacity 0.2s, transform 0.2s;
}

// Subclass items
.subclassBase {
  border-color: transparent !important;
}

// The bar we show for items that have some progress on them
.xpBar {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: auto;
  left: $item-border-width + 2px;
  right: $item-border-width + 2px;
  opacity: 1;
  top: $item-border-width + 2px;
  height: calc(var(--item-size) / 9);
}
.xpBarAmount {
  height: 100%;
  background-color: $xp;
}

// The container for the tag/notes/wishlist icons
.icons {
  position: absolute;
  left: $item-border-width + 2px;
  top: calc(var(--item-size) - #{$badge-height} - #{$item-border-width});
  display: flex;
  flex-direction: row;
}

// Individual icons in the icon tray
.icon {
  display: block;
  position: static;
  width: calc(var(--item-size) / 5);
  height: calc(var(--item-size) / 5);
  font-size: calc(var(--item-size) / 5);
  margin-right: 1px;
  color: #29f36a; // #5eff92;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.8));

  :global(.ms-edge .item) & {
    // https://github.com/DestinyItemManager/DIM/issues/3291
    filter: none !important;
  }
}

.subclass {
  position: absolute;
  width: calc(var(--item-size) * 0.5);
  height: calc(var(--item-size) * 0.5);
  left: calc(var(--item-size) * 0.25);
  top: calc(var(--item-size) * 0.25);
}
@mixin subclass-path-indicator {
  background-image: url('../../images/subclass-border.svg');
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}
.subclassPathTop {
  &::before {
    @include subclass-path-indicator;
  }
}
.subclassPathMiddle {
  &::before {
    @include subclass-path-indicator;
    transform: rotate(90deg);
  }
}
.subclassPathBottom {
  &::before {
    @include subclass-path-indicator;
    transform: rotate(180deg);
  }
}
