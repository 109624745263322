.rating-icon {
  &.godroll {
    color: #0b486b;
    font-size: 0.8em !important;
  }
  &.trashlist {
    color: #d14334;
    font-size: 0.8em !important;
  }
}
