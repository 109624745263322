.flexWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.resetButton {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  &:focus {
    outline: none;
  }
}
