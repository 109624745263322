.specialtyModIcon {
  background-position: center;
  display: inline-block;
  background-size: 150%;
  border-radius: 50%;
  // This causes rendering artifacts (wrong z-order, repaint on scroll)
  // filter: drop-shadow(0 0 0 white);
}

.lowRes {
  background-size: 196% !important;
}
