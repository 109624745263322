.fractionalPowerLevel {
  white-space: nowrap;

  .fraction {
    letter-spacing: 0.12em;

    sup {
      vertical-align: top;
    }
    sub {
      vertical-align: bottom;
    }

    sub,
    sup {
      font-size: 75%;
      line-height: 1;
    }
  }
}
