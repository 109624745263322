@import '../variables.scss';

.move-popup-tabs {
  display: flex;
  justify-content: space-around;
}
.move-popup-tab {
  color: hsl(0, 0%, 80%);
  padding: 5px 0 3px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #222;
  background-color: black;
  &.selected {
    opacity: 1;
    border-bottom: 2px solid $orange;
  }
  &:hover {
    color: white;
    background-color: #3f3f3f;
  }
}

/**
 * Move Popup - Details
 */

.item-popup-collapsed {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.item-details-body {
  overflow: hidden;
}

.item-details {
  margin: 10px;
  box-sizing: border-box;

  &.warning {
    background: scale-color($red, $lightness: -90%);
    border-top: 4px solid $red;
    padding: 8px;
    white-space: normal;
    max-width: fit-content;
    a {
      color: white;
      font-size: 12px;
    }
  }
}

.seasonal-expiration {
  color: rgb(218, 149, 45);
  .app-icon {
    margin-right: 4px;
  }
}

.item-description {
  margin: 5px 10px;
  white-space: pre-wrap;
  font-style: italic;
}

.item-shader {
  display: block;
  margin: 15px auto;
}

.masterwork-progress {
  margin: 4px 0px;
  padding: 4px 10px 4px;
  background: #333;

  span {
    color: $orange;
    font-weight: bold;
  }

  img {
    width: 16px;
    height: 16px;
    margin-bottom: -4px;
  }
}

.item-perks {
  margin: 5px 10px;
}

.item-perk {
  display: flex;
  flex-direction: row;

  img {
    height: 48px;
    width: 48px;
  }
}

.item-perk-info {
  flex: 1;
  margin-left: 8px;
}
.item-perk-name {
  font-weight: bold;
  margin-bottom: 2px;
}
.failure-reason {
  color: white;
  background-color: #923c3c;
  margin: 0;
  padding: 2px 8px;
}

.sheet {
  .move-popup {
    width: auto;
    box-shadow: none;
  }
}

.item-popup {
  .sheet-header {
    padding: 0;
    border: none;
  }
  .sheet-close {
    padding: 12px 10px;
    color: black;
    opacity: 0.7;
  }
  .sheet-handle div {
    background-color: black;
    opacity: 0.7;
  }

  &.is-Uncommon,
  &.is-Rare,
  &.is-Legendary {
    .sheet-close {
      color: white;
    }
    .sheet-handle div {
      background-color: white;
    }
  }
}

.owned-icon,
.acquired-icon {
  width: calc(var(--item-size) / 4) !important;
  height: calc(var(--item-size) / 4) !important;
  font-size: calc(var(--item-size) / 6) !important;
  border-radius: 50%;
  padding: 3px;
  box-sizing: border-box;
  .mods & {
    width: calc(var(--item-size) / 2.5) !important;
    height: calc(var(--item-size) / 2.5) !important;
    font-size: calc(var(--item-size) / 5) !important;
    vertical-align: -0.5em;
    padding: 0px;
    filter: invert(1);
    background: none !important;
  }
}

.acquired-icon {
  background: #3c94ff;
}

.owned-icon {
  background: #44bd32;
}
