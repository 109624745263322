.reward {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0 0 0;

  img {
    width: 17px;
    height: 17px;
    margin-right: 4px;
  }
}
