@import '../variables.scss';

.sockets {
  display: flex;
  flex-direction: column;
  margin: -12px -10px -12px -10px;
}

.socket {
  composes: flexRow from '../dim-ui/common.m.scss';
  align-items: flex-start;
  border-bottom: 1px solid #333;
  padding: 6px 10px;

  &:last-child {
    border-bottom: none;
  }
}

.plug {
  composes: flexRow from '../dim-ui/common.m.scss';
  align-items: flex-start;
  margin-right: 1em;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  h2 {
    font-size: 12px;
    line-height: 12px;
    margin: calc((24 / 50 * var(--item-size) + 4px) / 2 - 6px) 0 4px 0;
  }
}

.perkIcon {
  grid-area: icon;
  align-self: flex-start;
  position: relative;
  width: calc(24 / 50 * var(--item-size));
  height: calc(24 / 50 * var(--item-size));
  display: block;
  border: 1px solid white;
  border-radius: 50%;
  padding: 1px;
  flex-shrink: 0;

  > img {
    height: 100%;
    width: 100%;
  }

  .selected & {
    background-color: $orange;
  }
  .plugged & {
    background-color: #4887ba;
  }
  :global(.thumbs-up) {
    position: absolute;
    top: -6px;
    right: -8px;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.9));
  }
}

.perkInfo {
  composes: flexColumn from '../dim-ui/common.m.scss';
  align-items: flex-start;
  margin-left: 6px;
  white-space: pre-wrap;

  > div {
    color: #aaa;
  }
}

.selected {
  flex: 1;
}

.disabled {
  opacity: 0.5;
}

.plugLabel {
  margin-left: 1em;
}
