.startButtonDiv {
  margin-top: 6px;

  .startButton {
    padding: 0;
    display: flex;
    border: none;
    background-color: #0d8109;
    color: #fff;
    cursor: pointer;
    margin-top: 8px;
    span.icon {
      margin: auto;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #0a5007;
    }
    span.text {
      padding: 9px;
      display: flex;
      white-space: nowrap;
      justify-content: center;
      align-items: center;
    }
  }
}
