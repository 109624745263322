@import '../variables.scss';

.button {
  composes: dim-button from global;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu {
  background: black;
  color: white;
  font-size: 12px;
  z-index: 100;
  position: absolute;
  &.open {
    outline: 2px solid change-color($orange, $alpha: 0.4) !important;
  }
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 10em;
  padding: 6px 9px;
  @include phone-portrait {
    padding: 10px 16px;
    font-size: 14px;
  }

  :global(.app-icon) {
    color: white !important;
    width: 16px;
    text-align: center;
    margin-right: 4px;
    font-size: 12px !important;
    height: 12px;
  }
}

.highlighted {
  background-color: $orange;
  color: black !important;
  :global(.app-icon) {
    color: black !important;
  }
}

.disabled {
  color: #999 !important;
  :global(.app-icon) {
    color: #999 !important;
  }
  &.highlighted {
    background-color: transparent;
  }
}

.arrow {
  font-size: 10px !important;
  width: 10px !important;
  height: 10px !important;
  margin-left: 10px;
}
