.classIcon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.label {
  position: relative;
  font-weight: bold;
  font-size: 13px;
  text-shadow: 1px 1px 2px black;
}

.dimmedBg {
  opacity: 0.6;
}
