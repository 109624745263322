.inlineStatIcon {
  cursor: pointer;
  display: inline-block;
  img {
    display: inline-block;
    vertical-align: bottom;
    height: 1.5em;
  }
}
.divider::after {
  opacity: 0;
  content: '+';
}
.activeStatLabels {
  img {
    filter: drop-shadow(0 0 0 white);
  }
  .divider::after {
    opacity: 1;
  }
}
.inactiveStatLabels {
  img {
    opacity: 0.4;
  }
  &.readOnly {
    display: none;
  }
  &:not(:empty) {
    &::before {
      content: '(';
    }
    &::after {
      content: ')';
    }
  }
}
