@import '../../variables.scss';

.footer {
  composes: flexRow from '../../dim-ui/common.m.scss';
  align-items: center;
}

.selectedMods {
  composes: flexWrap from '../../dim-ui/common.m.scss';
  flex: 1;
  align-items: center;

  > * {
    margin-right: 4px;
  }
}

.submitButton {
  composes: dim-button from global;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: 8px;
}
