.time_modal_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  &.show_modal_container-enter {
    opacity: 0;
  }
  &.show_modal_container-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  &.show_modal_container-exit {
    opacity: 1;
  }
  &.show_modal_container-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
  .show_modal-enter {
    transform: translateY(-10px);
  }
  .show_modal-enter-active {
    transform: translateY(0px);
  }
  .show_modal-exit {
    transform: translateY(0px);
  }
  .show_modal-exit-active {
    transform: translateY(-10px);
  }
  .time_modal {
    width: 380px;
    margin: 0;
    z-index: 1001;
    text-align: left;
    color: #000;
    border-radius: 10px;
    background: #fff;
    .time_modal_header {
      font-size: 21px;
      padding: 16px;
      color: rgba(0, 0, 0, 0.85);
      border-bottom: 1px solid rgba(34, 36, 38, 0.15);
      font-weight: bold;
    }
    .time_modal_body {
      display: block;
      font-size: 1em;
      line-height: 1.4;
      padding: 1.5rem;
      p {
        margin: 0;
        margin-bottom: 10px;
        font-size: 12px;
      }
      input[type='datetime'] {
        display: inline;
      }
    }
    .time_modal_footer {
      padding: 1rem 1rem;
      border-top: 1px solid rgba(34, 36, 38, 0.15);
      text-align: right;
      button {
        margin-left: 0.75em;
        color: #fff;
        text-shadow: none;
        background-image: none;
        padding: 10px 15px;
        border: none;
        border-radius: 5px;
      }
      .cancel_time_modal {
        background-color: #db2828;
        box-shadow: 0 0 10px 2px #9f0000 inset;
      }
      .okay_time_modal {
        background-color: #21ba45;
        box-shadow: 0 0 10px 2px #02881b inset;
      }
    }
  }
}
