.milestone-modifier {
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0.5;
  cursor: pointer;

  img {
    width: 12px;
    height: 12px;
    margin: 1px 6px 0 2px;
  }

  .milestone-modifier-description {
    font-size: 10px;
    color: #a1a2a2;
  }

  .move-popup-details & {
    opacity: 1;
    margin-bottom: 2px;

    img {
      width: 16px;
      height: 16px;
    }
    .milestone-modifier-description {
      font-size: 12px;
    }
  }
}
