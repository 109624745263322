body {
  margin: 0;
}
.mainLoaderContainer {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
}
.loaderSpan {
  position: absolute;
  border-radius: 50%;
  animation: rotate 1.4s ease-in-out infinite;
}
.span1 {
  width: 50px;
  height: 50px;
  border: 4px solid #d500f9;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  animation-delay: 50ms;
}
.span2 {
  width: 60px;
  height: 60px;
  border: 4px solid #e91e63;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  animation-delay: 100ms;
}
.span3 {
  width: 70px;
  height: 70px;
  border: 4px solid #26a69a;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  animation-delay: 150ms;
}
.span4 {
  width: 80px;
  height: 80px;
  border: 4px solid #cddc39;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  animation-delay: 200ms;
}
.span5 {
  width: 90px;
  height: 90px;
  border: 4px solid #7cb342;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  animation-delay: 250ms;
}
.span6 {
  width: 100px;
  height: 100px;
  border: 4px solid #ffa726;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  animation-delay: 300ms;
}
.span7 {
  width: 110px;
  height: 110px;
  border: 4px solid #ff8a65;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  animation-delay: 350ms;
}
.span8 {
  width: 120px;
  height: 120px;
  border: 4px solid #ef5350;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  animation-delay: 400ms;
}
.span9 {
  width: 130px;
  height: 130px;
  border: 4px solid #4a148c;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
  animation-delay: 450ms;
}
@keyframes rotate {
  0% {
    transform: rotate(-45deg);
  }
  90%,
  100% {
    transform: rotate(315deg);
  }
}
