.show_hide_data,
.show_offline_data {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff6800;
  border: 1px solid #c55307;
  box-shadow: none;
  white-space: nowrap;
  padding: 0;
  display: flex;
  color: #fff;
  cursor: pointer;
  span.icon {
    margin: auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #c04e03;
  }
  span.text {
    padding: 9px;
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
  }
}
.show_offline_data {
  right: 145px;
}
