@import '../variables.scss';

.on-drag-enter,
.on-drag-hover {
  background-color: rgba(200, 200, 200, 0.2);
}

.sub-bucket,
.subBucket {
  min-height: var(--item-size);
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--item-size));
  gap: var(--item-margin);
  align-content: flex-start;
  align-items: flex-start;
  padding: 4px 0 calc(var(--item-margin) + 4px) 0;
  position: relative;

  &:empty {
    min-height: 0;
    padding: 0;
  }

  &.on-drag-hover {
    box-shadow: inset 0 0 6px 0 rgba(200, 200, 200, 0.7);
  }

  &:before {
    content: '';

    opacity: 0;

    width: 100%;
    height: 100%;

    position: absolute;

    z-index: 9;

    display: none;

    .drag-perf-show & {
      display: block;
    }
  }

  &.equipped {
    display: flex;
    flex-direction: column;
    width: calc(var(--item-size) + #{$equipped-item-total-outset});
    margin-right: var(--item-margin);
  }

  &.unequipped {
    flex: 1;
    width: 100%;

    .vault & {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.not-equippable {
    padding-left: 6px;
    @include phone-portrait {
      padding-left: 4px;
    }
  }

  .armor-class-icon {
    box-sizing: border-box;
    width: var(--item-size);
    height: calc((var(--item-size) + ((var(--item-size) / 5) + 4px) - 1px));
    font-size: calc(var(--item-size) * 0.71);
    padding: 8px;
    color: #999;
  }
}

.pull-item-button {
  font-size: calc((var(--item-size) / 3));
  margin: calc((var(--item-size) / 8) - 4px) auto calc((var(--item-size) / 8) - 4px) auto;
  padding: 4px;
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 1;
  }
}
