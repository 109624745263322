@import '../variables.scss';

$legendaryBg: #522f65;
$exoticBg: #ceae33;
$basicBg: #c3bcb4;
$rareBg: #5076a3;
$commonBg: #366f42;

// The image within the overall item. Global because it's referenced by other styles.
:global(.item-img) {
  display: block;
  width: var(--item-size);
  height: var(--item-size);
  box-sizing: border-box;
  border: $item-border-width solid #ddd;

  &:focus {
    outline: none;
  }

  // Used by vendors
  &:global(.transparent) {
    border-color: transparent;
  }

  &.legendary {
    background-color: $legendaryBg;
  }
  &.exotic {
    background-color: $exoticBg;
  }
  &.basic {
    background-color: $basicBg;
  }
  &.rare {
    background-color: $rareBg;
  }
  &.common {
    background-color: $commonBg;
  }
}

// Masterwork item image
.masterwork {
  border-color: $masterwork-border-color;
}

// Completed items or capped stackables image
.complete {
  border-color: $gold;
}

// Engrams and packages
.borderless {
  border-color: transparent;
}

// The overlay image for masterworks
.masterworkOverlay {
  box-sizing: border-box;
  top: $item-border-width;
  left: $item-border-width;
  border-width: 0px;
  height: calc(var(--item-size) - #{2 * $item-border-width});
  width: calc(var(--item-size) - #{2 * $item-border-width});
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: calc((var(--item-size) - #{2 * $item-border-width}) * (96 / 90))
    calc((var(--item-size) - #{2 * $item-border-width}) * (96 / 90));

  background-image: url('../../images/masterwork.png');
}
.exoticMasterwork {
  background-image: url('../../images/exotic-masterwork.png');
}

.iconOverlay {
  overflow: hidden;
  top: $item-border-width;
  left: $item-border-width;
  height: calc(var(--item-size) - #{2 * $item-border-width});
  width: calc(var(--item-size) - #{2 * $item-border-width});
  position: absolute;
  & > img {
    top: 0;
    left: 0;
    height: calc(var(--item-size) - #{2 * $item-border-width});
    width: calc(var(--item-size) - #{2 * $item-border-width});
    position: absolute;
  }
}

.energyCostOverlay {
  position: absolute;
  top: $item-border-width;
  bottom: $item-border-width;
  right: $item-border-width;
  left: $item-border-width;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  pointer-events: none;
}

.energyCost {
  position: absolute;
  top: $item-border-width;
  bottom: $item-border-width;
  right: $item-border-width;
  left: $item-border-width;
  pointer-events: none;

  text {
    fill: white;
  }
}
