.entry {
  composes: entry from './ActionButton.m.scss';
}

.label {
  margin-left: 8px;
}

.tagSelectorLabelHidden {
  padding: 0;
  :global(.dim-button) {
    border-radius: 0;
    padding: 6px 8px;
    > div > :global(.app-icon) {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      margin: 0;
      height: 24px;
      width: 24px;
    }
  }
}
