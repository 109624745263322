@import '../variables.scss';

.errorPanel {
  margin: 16px 0;
  padding: 0.85em;
  background: scale-color($red, $lightness: -90%);
  border-top: 4px solid $red;

  h2 {
    margin: 0 0 8px 0 !important;
    letter-spacing: normal !important;
    text-transform: none !important;
  }
  a {
    color: white;
    font-size: 12px;
  }
}

.twitterLinks {
  composes: flexRow from '../dim-ui/common.m.scss';
  flex-wrap: wrap;
  @include horizontal-space-children(8px);
  gap: 8px 0;

  :global(.fa-twitter) {
    font-size: 1.5em;
    vertical-align: bottom;
  }
}

.errorCode {
  color: #888;
  margin: 0 0 0 8px;
  font-size: 12px;
  float: right;
}

.twitters {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @include phone-portrait {
    flex-direction: column;
  }

  > div > div {
    height: 400px;
    outline: 4px solid rgba(255, 255, 255, 0.3);
  }
}
