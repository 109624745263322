// TODO: Move to Objective.tsx

.objective-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
}

.objective-checkbox {
  width: 17px;
  height: 17px;
  border: 1px solid #999;
  margin-right: 4px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.3);
}

.objective-checkbox.objective-complete::after {
  content: '';
  display: block;
  margin: 2px;
  height: 11px;
  width: 11px;
  background-color: #6dcc66;
}

.objective-complete {
  opacity: 0.5;
  .objective-progress {
    background-color: #222;
  }
  .objective-progress-bar {
    display: none;
  }
  .objective-checkbox::after {
    content: '';
    display: block;
    margin: 2px;
    height: 11px;
    width: 11px;
    background-color: #5ea16a;
  }
}

.objective-progress {
  flex: 1;
  background-color: #333;
  position: relative;
  min-height: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}

.objective-integer {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

.objective-progress-bar {
  background-color: #5ea16a;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.objective-description {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 4px;
  flex: 1;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}

.objective-text {
  z-index: 2;
  margin-right: 4px;
  backface-visibility: hidden;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
}

.objective-boolean {
  .objective-progress-bar,
  .objective-text {
    display: none;
  }
  .objective-progress {
    background-color: transparent;
  }
  .objective-description {
    padding: 0;
  }
}
