.dropdownButtonContainer {
  position: relative;
  &.open {
    background-color: #ffffffc9;
  }
  .stopsessionoption {
    padding: 0;
    display: flex;
    border: none;
    background-color: #e1232d;
    color: #fff;
    cursor: pointer;
    margin: 4px;
    span.icon {
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #b3060f;
    }
    span.text {
      padding: 8px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.stop {
      background-color: #e1232d;
    }
    &.partial {
      background-color: #d14c53;
      span.icon {
        background-color: #b82c33;
      }
    }
    &.extended {
      background-color: #d14c53;
      span.icon {
        background-color: #b82c33;
      }
    }
    &.full {
      background-color: #d14c53;
      span.icon {
        background-color: #b82c33;
      }
    }
  }
  .dropdownOptionsdiv {
    position: absolute;
    bottom: 40px;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: #ffffffc9;
    .customSelect {
      margin: 4px;
      padding: 8px;
    }
  }
}
