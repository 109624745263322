@import '../variables.scss';

.button {
  composes: dim-button from global;
  align-self: center;
  display: inline-flex;
  align-items: center;
  @include horizontal-space-children(4px);
  &:hover {
    & .badge {
      background-color: black;
      color: $orange;
    }
  }
}

.badge {
  border-radius: 50%;
  border-radius: 10px;
  background: $orange;
  padding: 0 4px;
  color: black;
  font-size: 9px;
}
