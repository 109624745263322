.singleItem {
  display: flex;
  border: 1px solid #565656;
  margin: 4px auto;
  padding: 0 0px;

  .singleImageContainer {
    .iconImage {
      width: 60px;
      height: 60px;
      border: 2px solid #ff680078;
      border-radius: 5px;
    }
  }

  .infocontainer {
    flex: 1;
    padding: 2px 10px;
    position: relative;

    p {
      margin: 0;
    }

    .removeButton {
      padding: 2px 4px;
      border: none;
      background-color: #d00b12;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      position: absolute;
      bottom: 6px;
      right: 4px;
    }
  }
}

.removedData {
  font-size: 18px;
  margin: 2px;
  font-weight: 900;
  color: #ff6800;
  border: 1px solid;
  padding: 10px;
}
.customQuantity {
  background-color: #000;
  border: 1px solid #2368b9;
  color: #fff;
  max-width: 45px;
}

.singleRank {
  border: 1px solid #565656;
  // padding: 2px 10px;
  margin: 10px auto;
  p.rank_title {
    text-align: center;
    font-size: 18px;
    margin: 4px;
    font-weight: bold;
  }

  .rank_data_old_new table {
    border-collapse: collapse;
    width: 100%;
  }

  .rank_data_old_new table td,
  .rank_data_old_new table th {
    border: 1px solid #292929;
    white-space: nowrap;
    padding: 8px;
  }

  input.rank_inputs {
    width: 46px;
    background-color: #333;
    border: 1px solid #333;
    color: #fff;
    font-size: 14px;
    padding: 0 0 0 4px;
  }
}
