.takeCompareButtonDiv {
  margin-top: 8px;
  display: flex;
  .takeCompareButton {
    color: #fff;
    padding: 0;
    display: flex;
    background-image: linear-gradient(-60deg, #0eb559 0%, #172f17 100%);
    border: none;
    cursor: pointer;
    span.icon {
      margin: auto;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(60deg, #0eb559 0%, #172f17 100%);
    }
    span.text {
      padding: 9px;
      display: flex;
      white-space: nowrap;
      justify-content: center;
      align-items: center;
    }
  }
  .compareSelect {
    margin-left: 6px;
    width: 100%;
  }
}
.compareIcon {
  width: 10px;
  transform: scale(2);
  height: 13px;
}

.overlay-compare-custom-data-item-compare {
  background: #0000006e;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
