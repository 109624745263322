.show_seggested_modal_button {
  padding: 0;
  display: flex;
  border: none;
  color: #fff;
  cursor: pointer;
  margin: 4px;
  span.icon {
    margin: auto;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(60deg, #12974e 0%, #2a582a 100%);
  }
  span.text {
    padding: 9px;
    display: flex;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(-60deg, #12974e 0%, #2a582a 100%);
  }
}

.selected_product_listing_main_listing {
  display: flex;
  flex-wrap: wrap;
  .single_product_nag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000000;
    padding: 5px 10px;
    margin: 4px;
    border-radius: 50px;
    color: white;
    .single_product_nag_image_title {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
      }
      .single_product_nag_title {
        display: block;
        font-weight: bold;
        margin-right: 15px;
        margin-left: 8px;
        max-width: 220px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .single_product_nag_remove {
      background-color: #fff;
      color: #000;
      padding: 0px 5px;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
