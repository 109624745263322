@import '../variables.scss';

.filter-input {
  border-radius: 0;
  -webkit-appearance: none;
  font-size: 13px;
}

.filterHelp {
  max-width: 800px;
  margin: 0 auto;
}

.search-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #313233;
  padding-left: 5px;
  padding-right: 5px;
  height: 32px;
  border-radius: 4px;
  min-width: 350px;
  box-sizing: border-box;

  ::placeholder {
    color: #999;
  }

  .search-bar-icon {
    color: #999;
    margin: 0 6px 0 4px;
    font-size: 12px !important;
  }

  @include phone-portrait {
    width: auto;
    height: 34px;
  }

  &:hover,
  &:focus {
    //background: black;
    outline: none;
  }

  input[type='text'] {
    border: none;
    color: white;
    background: transparent;
    outline: none;
    flex: 1;
    width: 0;
    padding: 7px 0;
  }
}

.search-link {
  flex: 1;
  font-size: 13px;
  margin: 0 8px;
  @include phone-portrait {
    margin: 0 12px;
  }

  .search-filter {
    background: rgba(0, 0, 0, 0.4);
  }
}

.mobile-search-link {
  display: block;
  width: 100%;
  height: 44px;
  z-index: 1;
  margin: 0 !important;

  .search-filter {
    height: 100%;
    padding: 0 0 0 8px;
    margin: 0;
    border-radius: 0;
    border-bottom: 1px solid #999;
    background: black;
  }

  .filter-input {
    height: 34px;
    margin-top: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .filter-bar-button {
    top: 0;
  }

  .app-icon {
    font-size: 18px !important;
  }

  input,
  select:focus {
    font-size: 16px; // so iOS doesn't zoom
  }
}

.search-button {
  display: none;
  @include phone-portrait {
    display: inline-block;
  }
}

.filter-bar-button {
  margin: 0 6px;
  padding: 0;
  -webkit-appearance: none;
  display: inline-block;
  background: transparent;
  border: 0;
  cursor: pointer;

  :focus {
    outline: 1px solid $orange;
  }

  @include phone-portrait {
    margin-right: 8px !important;
  }

  .app-icon {
    font-size: 14px !important;
    color: #999 !important;
    // Increase touch target size
    padding: 4px;
    margin: -4px;
    margin-left: 4px;
    &:first-child {
      margin-left: -4px;
    }
    &:hover {
      color: #e8a534 !important;
    }
  }
  &.bulk-tag-select {
    background-color: #444;
    position: relative;
    padding: 4px;
    -webkit-appearance: menulist;
    option {
      color: white;
      background: #444;
    }
  }

  span,
  a {
    margin: 0 !important;
  }
}

/* Textcomplete */

.dropdown-menu {
  background-color: white;
  transform: translate(0, -4px);
  border-radius: 4px;
  overflow: hidden;
  .textcomplete-header,
  .textcomplete-footer {
    display: none;
  }
  li {
    padding: 4px 8px;
    &:nth-child(2) {
      border-top: none;
    }
    &:hover {
      background-color: #e8a534;
    }
  }
  .active {
    background-color: #e8a534;
  }
  a {
    color: black;
    font-size: 12px;
    &:hover {
      cursor: pointer;
    }
    text-decoration-line: none;
  }

  @include phone-portrait {
    a {
      font-size: 16px;
    }
    li {
      padding: 8px 10px;
    }
  }
  list-style: none;
  padding: 0;
  margin: 0;
}
