// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Move Popup
//
// The popup displaying info and actions for an single item.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

@import '../variables.scss';

.movePopupDialog {
  // Mod drawer has z-index of 12 and needs to sit over this
  z-index: 11;

  --backgroundColor: 0, 0%, 0%;

  &.exotic {
    --backgroundColor: 48, 71%, 5%;
  }
  &.legendary {
    --backgroundColor: 279, 36%, 5%;
  }
  &.rare {
    --backgroundColor: 213, 34%, 6%;
  }
  &.uncommon {
    --backgroundColor: 126, 35%, 5%;
  }
  &.common {
    --backgroundColor: 0, 0%, 7%;
  }

  &[data-popper-placement^='top'] .arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    bottom: -5px;
  }

  &[data-popper-placement^='bottom'] .arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    top: -5px;

    border-bottom-color: white;

    &.exotic {
      border-bottom-color: $exotic;
    }
    &.legendary {
      border-bottom-color: $legendary;
    }
    &.rare {
      border-bottom-color: $rare;
    }
    &.uncommon {
      border-bottom-color: $uncommon;
    }
    &.common {
      border-bottom-color: $common;
    }
  }
  &[data-popper-placement^='right'] .arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    left: -5px;
  }
  &[data-popper-placement^='left'] .arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    right: -5px;
  }
  textarea {
    resize: vertical;
  }
}

.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  border-color: black;
  z-index: -1;
}

.popupBackground {
  background-color: #{'hsl(var(--backgroundColor))'};
  contain: content;
  box-shadow: 0 -1px 24px 4px #161626;
  @include phone-portrait {
    box-shadow: none;
  }
}

.desktopPopupRoot {
  pointer-events: none;
  .arrow {
    border-color: #161626;
  }
}

.desktopPopup {
  display: flex;
  flex-direction: row;
  .movePopupDialog[data-popper-placement^='right'] & {
    flex-direction: row-reverse;
  }
}

.desktopPopupBody {
  width: 320px;
  display: block;
  pointer-events: auto;
}

.desktopActions {
  display: block;
  > div {
    pointer-events: auto;
  }
}

.mobileItemActions {
  padding: 5px 0;
  display: flex;
  position: sticky;
  top: 0;
  justify-content: space-between;
  z-index: 1;
  background: #111;
  border-bottom: 1px solid #333;
  border-top: 1px solid #333;
  &:empty {
    display: none;
  }
}

.mobileMoveLocations {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
