.product_modal_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  &.show_modal_container-enter {
    opacity: 0;
  }
  &.show_modal_container-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  &.show_modal_container-exit {
    opacity: 1;
  }
  &.show_modal_container-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
  .show_modal-enter {
    transform: translateY(-10px);
  }
  .show_modal-enter-active {
    transform: translateY(0px);
  }
  .show_modal-exit {
    transform: translateY(0px);
  }
  .show_modal-exit-active {
    transform: translateY(-10px);
  }
  .product_modal {
    width: 400px;
    margin: 0;
    z-index: 1001;
    text-align: left;
    color: #000;
    border-radius: 10px;
    background: #fff;
    .product_modal_header {
      font-size: 21px;
      padding: 16px;
      color: rgba(0, 0, 0, 0.85);
      border-bottom: 1px solid rgba(34, 36, 38, 0.15);
      font-weight: bold;
    }
    .product_modal_body {
      display: block;
      font-size: 1em;
      line-height: 1.4;
      padding: 1.5rem;
      .selected_product_listing {
        display: flex;
        flex-wrap: wrap;
        .single_product_nag {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #000000;
          padding: 5px 10px;
          margin: 4px;
          border-radius: 50px;
          color: white;
          .single_product_nag_image_title {
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
            .single_product_nag_title {
              display: block;
              font-weight: bold;
              margin-right: 15px;
              margin-left: 8px;
              width: 85px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .single_product_nag_remove {
            background-color: #fff;
            color: #000;
            padding: 0px 5px;
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }
      .product_modal_searched_list {
        max-height: 200px;
        overflow: auto;
        background-color: #f1f1f1;
        padding: 4px 0;
        position: relative;
        min-height: 50px;
        .input_loader {
          position: absolute;
          top: 0;
          left: 0;
          background-color: #4f4f4f66;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .search_loader_icon {
            width: 30px;
            height: 30px;
            border: 5px solid #ffffff;
            border-radius: 50%;
            border-top: 5px solid #ff6800;
            animation: rotate 500ms infinite linear;
          }
          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }
        .single_searched_product {
          padding: 6px;
          margin-bottom: 4px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.selected {
            background-color: #e1e1e1;
          }
          .product_name_image {
            display: flex;
            align-items: center;
            img {
              width: 35px;
              height: 35px;
              border-radius: 50%;
            }
            span {
              margin-left: 5px;
            }
          }
          .clear_selected_services {
            display: block;
            font-size: 16px;
            padding: 0px 6px;
          }
        }
      }
      p {
        margin: 0;
        margin-bottom: 10px;
        font-size: 12px;
      }
      input[type='text'] {
        width: 95%;
        margin: auto;
        margin-bottom: 12px;
        padding: 5px 0 5px 8px;
      }
    }
    .product_modal_footer {
      padding: 1rem 1rem;
      border-top: 1px solid rgba(34, 36, 38, 0.15);
      text-align: right;
      button {
        margin-left: 0.75em;
        color: #fff;
        text-shadow: none;
        background-image: none;
        padding: 10px 15px;
        border: none;
        border-radius: 5px;
      }
      .cancel_product_modal {
        background-color: #db2828;
        box-shadow: 0 0 10px 2px #9f0000 inset;
      }
      .okay_product_modal {
        background-color: #21ba45;
        box-shadow: 0 0 10px 2px #02881b inset;
      }
    }
  }
}
