.accountSelect {
  color: white;
  height: auto;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1em;

  a {
    text-decoration: none;
  }
}

.logout {
  composes: account from './Account.m.scss';
  margin-top: 20px;
}
