.single_item {
  border-radius: 5px;
  margin: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    to left bottom,
    #79997c,
    #619585,
    #4e8f90,
    #488799,
    #517d9c,
    #597594,
    #5f6d8b,
    #636581,
    #5e5e73,
    #595866,
    #535259,
    #4c4c4c
  );
  .property {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    .property-title {
      font-size: 18px;
      font-weight: bold;
    }
    .property-value {
      font-size: 16px;
      white-space: nowrap;
      margin-left: 10px;
      overflow: hidden;
    }
  }
  .image_container {
    max-width: 80px;
    width: 100%;
    height: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000;
    box-shadow: 0 0 8px 0px #000;
    margin: 8px;
    border-radius: 5px;
  }
  .information {
    display: flex;
    align-items: center;
    margin-left: 8px;
    table {
      tr {
        td {
          font-size: 16px;
        }
      }
    }
  }
  &.Mission {
    background-image: linear-gradient(
      to left bottom,
      #79997c,
      #619585,
      #4e8f90,
      #488799,
      #517d9c,
      #597594,
      #5f6d8b,
      #636581,
      #5e5e73,
      #595866,
      #535259,
      #4c4c4c
    );
  }
  &.Common {
    background-image: linear-gradient(
      to right top,
      #c2c2c2,
      #b2b2b2,
      #a2a2a2,
      #929292,
      #838383,
      #797979,
      #707070,
      #666666,
      #5f5f5f,
      #595959,
      #525252,
      #4c4c4c
    );
  }
  &.Uncommon {
    background-image: linear-gradient(
      to right top,
      #7cf495,
      #70e889,
      #65dc7d,
      #59d071,
      #4dc465,
      #47b95e,
      #41ae58,
      #3ba351,
      #3a964e,
      #39894a,
      #387c46,
      #366f42
    );
  }
  &.Rare {
    background-image: linear-gradient(
      to right top,
      #96c6ff,
      #89b6ed,
      #7ba7db,
      #6e98ca,
      #6189b9,
      #5680b4,
      #4a77ae,
      #3e6ea9,
      #3068ad,
      #2262b1,
      #125bb4,
      #0054b7
    );
  }
  &.Legendary {
    background-image: linear-gradient(
      to right top,
      #ad5cda,
      #a953d9,
      #a54ad8,
      #a141d7,
      #9d36d6,
      #9533cc,
      #8d2fc2,
      #852cb8,
      #782ea3,
      #6b308d,
      #5f3079,
      #522f65
    );
  }
  &.Exotic {
    background-image: linear-gradient(
      to right top,
      #ffe271,
      #fedf69,
      #fcdc60,
      #fbd957,
      #fad64e,
      #f6d248,
      #f1cd43,
      #edc93d,
      #e5c23a,
      #ddbb38,
      #d6b535,
      #ceae33
    );
  }
}
