.emptyItem {
  width: var(--item-size);
  height: var(--item-size);
  border: 1px solid #ddd;
  box-sizing: border-box;
  > div,
  img {
    width: 100%;
    height: 100%;
  }
}
