.cost {
  composes: flexRow from '../dim-ui/common.m.scss';
  align-items: center;
  font-size: 10px;
  text-align: center;
  img {
    height: 12px;
    width: 12px;
    margin-left: 4px;
  }
}
