@import '../variables.scss';

.dim-static-page {
  padding: 16px 1em;
  font-size: 14px;
  user-select: auto;

  .about-logo {
    float: left;
    margin-right: 10px;
    margin-top: 8px;
  }
  .about-header {
    margin-bottom: 16px;
  }
  form {
    padding: 0;
  }
  h1 {
    flex-shrink: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin: 0;
    font-weight: 200;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      margin-right: 8px;
    }

    @media (max-width: 800px) {
      font-size: 20px;
    }
  }
  h2 {
    a {
      color: $orange;
      text-decoration: none;
      border-bottom: 1px solid scale-color($orange, $alpha: -50%);
    }
  }
  > p {
    margin: 5px 0;
  }
  dt {
    font-style: normal;
    font-weight: 400;
    font-size: 1.1em;
    margin-top: 10px;
    color: $orange;
  }
  dd {
    margin: 4px 0px 8px 0px;
  }
  .social {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 8px;
    margin: 8px 0;
  }
}
