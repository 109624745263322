.privacy {
  ol,
  p {
    color: #ccc;
    line-height: 1.2em;
  }
  li {
    margin-bottom: 4px;
    margin-top: 4px;
  }
}
