.offlineModeMainDiv {
  --filter-width: 20%;
  width: 100%;
  height: 100vh;
  background-color: #252525;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  padding: 10px;
  border: 1px solid #ff6800;
  display: block;
  box-sizing: border-box;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ff6800;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: darken(#ff6800, 10%);
  }
  h1 {
    text-align: center;
    font-size: 32px;
    height: 43px;
  }
  .offline-data-main-container {
    display: flex;
    flex-wrap: wrap;
    .filters-container {
      width: var(--filter-width);
      margin-right: 1%;
    }
    .collectiveItems {
      width: calc(99% - var(--filter-width));
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); // Previous value was 250px
      grid-template-rows: repeat(auto-fit, 100px);
      height: calc(100vh - 105px);
      overflow: auto;
      .offline_loadmore_button {
        background-color: #f66800;
        font-size: 40px;
        font-weight: bold;
        height: 88px;
        margin: 4px;
        color: #ffffff;
        border-radius: 10px;
        cursor: pointer;
      }
    }
    .searchBar {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      input#offlineItemSearchTextFieldId {
        width: 100%;
        padding: 5px 10px;
        background-color: #3b3b3b;
        border: 1px solid #949494;
        color: #ffffff;
        font-size: 15px;
        &:focus {
          outline: none;
        }
      }
      button {
        background-color: #4caf50;
        border: 1px solid #4caf50;
        box-shadow: none;
        white-space: nowrap;
        color: #fff;
        cursor: pointer;
        padding: 5px 10px;
        &.clear {
          background-color: #e53935;
          border: 1px solid #e53935;
        }
      }
    }
    .badgeContainer {
      max-height: calc(100vh - 158px);
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      button.badgeButton {
        background-color: #58587f;
        border: 1px solid #382c77;
        color: #fff;
        font-size: 15px;
        border-radius: 50px;
        cursor: pointer;
        white-space: nowrap;
        padding: 8px 18px;
        margin: 5px;
        &.select {
          background-color: #3f51b5;
          border: 1px solid #7986cb;
          color: #d4d4d4;
        }
      }
      button.badgeButton:hover {
        background-color: #3f51b5;
        border: 1px solid #7986cb;
        color: #d4d4d4;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .offline-data-main-container .filters-container {
      width: 100%;
    }
    .offline-data-main-container .collectiveItems {
      width: 100%;
    }
    .offline-data-main-container {
      max-height: calc(100vh - 110px);
      overflow: auto;
    }
    .offline-data-main-container .collectiveItems {
      height: auto;
      overflow: hidden;
      margin-top: 12px;
      grid-template-rows: none;
    }
    .offline-data-main-container .badgeContainer {
      max-height: 100%;
      justify-content: center;
    }
  }
}
