.mainArrayItemDiv {
  position: fixed;
  bottom: 45px;
  right: 5px;
  z-index: 1005;
  hr {
    border-color: #686868;
    margin: 10px auto;
    border-radius: 50px;
  }
  .refreshData {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    background-color: #ff6800;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
  }

  .arrayDataContainer {
    position: absolute;
    bottom: 10px;
    right: 0;
    padding: 10px;
    max-height: 85vh;
    min-height: 25vh;
    min-width: 250px;
    background-color: #000000;
    border: 1px solid #ff6800;
    border-radius: 5px;
    overflow: auto;

    &.hide {
      display: none;
    }

    .arrayDataInner {
      overflow: auto;
      margin-top: 10px;
    }
  }

  .gamertagDisplayMainDiv {
    position: fixed;
    top: 16px;
    right: 25px;
    z-index: 9;
    padding: 10px;
  }

  .gamertagTextDiv {
    padding: 6px;
    font-size: 16px;
    border: 1px solid #000;
    position: relative;
    background: #000;
    box-shadow: 0 0 7px #fff;
    border-radius: 5px;
  }

  .gamertagRemoveIcon {
    position: absolute;
    top: -8px;
    right: -12px;
    border: 2px solid #fff;
    line-height: 1;
    width: 1em;
    border-radius: 50%;
    height: 1em;
    text-align: center;
    background: #ff0000;
    padding: 2px;
    cursor: pointer;
  }

  span.gamertagText {
    display: flex;
    margin: 4px;
  }
}

.addedToListCheckMark {
  width: 100%;
  height: 100%;
  background-color: #ff680063;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.orderDetail {
  padding: 0 8px;
  .formContainer {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 2px;
    .customInput {
      width: 100px;
      margin: 0 5px 0 0;
      padding: 0 0 0 5px;
    }

    .customTextarea {
      height: 125px;
      border: 1px solid #8e8f8f;
      background-color: #313232;
      padding: 6px;
    }
    .twitch_link {
      border: 1px solid #8e8f8f;
      background-color: #313232;
      margin-bottom: 10px;
      padding: 6px;
      color: #fff;
      &:focus {
        outline: none;
      }
    }
  }
}

.offlineModeDisabled {
  display: none;
}
.offlineDivContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  .startOfflineButton {
    padding: 10px 20px;
    border: 2px solid #fff;
    background-color: #000;
    color: #fff;
    border-radius: 8px;
    transition: all 200ms ease-out;
    cursor: pointer;
    box-shadow: 0 0 30px 0 #a7a6a6;
    &:hover {
      box-shadow: 0 0 0 0 #a7a6a6;
    }
  }
  #offlineGamertagInputDivUniqueID {
    padding: 8px;
    margin-right: 12px;
    border-radius: 5px;
    background-color: #000;
    border: 1px solid #fff;
    color: #fff;
  }
}
.notifications-container {
  z-index: 1010 !important;
}
