@import '../variables.scss';

.searchBar {
  position: relative;
}

.open {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.menu {
  color: white;
  overflow: hidden;
  margin: 0;
  border-top: 0;
  background: #161616;
  position: absolute;
  z-index: 50;
  list-style: none;
  padding: 0;
  left: 0;
  right: 0;
  top: 32px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  @include phone-portrait {
    top: calc(100% + 1px);
    overflow-y: auto;
    overscroll-behavior: none;
    max-height: calc(var(--viewport-height) - var(--header-height));
  }
}

.openButton {
  composes: resetButton from '../dim-ui/common.m.scss';
  display: inline-block;
  margin-left: 5px;
  color: #999;
  font-size: 12px !important;
}

.menuItem {
  composes: flexRow from '../dim-ui/common.m.scss';
  align-items: flex-start;
  padding: 6px 9px 6px 9px;

  @include phone-portrait {
    align-items: center;
    padding: 14px 14px 14px 9px;
  }
}

.highlightedItem {
  background-color: $orange;
  color: black !important;
  .menuItemIcon {
    color: black;
  }
  .deleteIcon {
    color: #333;
    visibility: visible;
  }
}

.menuItemIcon {
  font-size: 12px !important;
  margin-right: 6px;
  margin-top: 5px;
  color: #999;
}

.menuItemQuery {
  white-space: normal;
  padding: 1px 0;
  overflow: hidden;
  max-height: 4em;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.menuItemHelp {
  flex: 1;
  color: #999;
  span {
    margin: 0 8px 0 24px;
    @include phone-portrait {
      // TODO: I don't have a good way to show these on mobile yet
      display: none;
    }
  }
  .highlightedItem & {
    color: #222;
  }
}

.deleteIcon {
  composes: resetButton from '../dim-ui/common.m.scss';
  font-size: 10px !important;
  margin-top: 4px;
  color: #999;
  padding: 0 2px;
  cursor: pointer;
  visibility: hidden;
  @media (hover: none) {
    visibility: visible;
  }
}

/* Highlighted spans of text in the autocompleter */
.textHighlight {
  display: inline-block;
  border-bottom: 1px dotted #ddd;
  margin-bottom: -1px;
  .highlightedItem & {
    border-bottom-color: #222;
  }
}

.keyHelp {
  margin-top: 2px;
  .highlightedItem & {
    color: #222;
    border-color: #222;
  }

  .menuItem:hover & {
    display: none;
  }
}

.saveSearchButton > :global(.app-icon) {
  color: $orange !important;
}
