@import '../variables.scss';

$control-color: rgba(255, 255, 255, 0.5);

.sheet {
  max-height: calc(var(--viewport-height) - var(--header-height) - 8px);
  left: 0;
  right: 0;
  position: fixed;
  backface-visibility: hidden;
  z-index: 12;
  bottom: 0;
  // Pin the sheet to just over the keyboard
  bottom: var(--viewport-bottom-offset);
  background-color: black;
  color: #e0e0e0;
  box-shadow: 0 -1px 24px 0px #222;
  user-select: none;
  overflow: hidden;

  .sheet-header {
    box-sizing: border-box;
    padding: 11px 48px 10px 10px;
    border-bottom: 1px solid #333;
    border-top: 5px solid $control-color;
    cursor: grab;
    // without a min-height, the border-bottom sticks through the close sheet button
    min-height: 56px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    > *:first-child {
      flex: 1;
    }

    h1 {
      @include destiny-header;
      font-size: 16px;
      margin: 0 0 8px 0;
      display: block;
    }
  }

  .sheet-footer {
    border-top: 1px solid #333;
    padding: 8px 10px;
    padding-bottom: Max(8px, env(safe-area-inset-bottom));
    flex-shrink: 0;
  }

  .sheet-container {
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: calc(var(--viewport-height) - var(--header-height) - 8px);
  }

  .sheet-contents {
    flex: 1;
    overflow-y: auto;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    z-index: 0;
    padding-bottom: env(safe-area-inset-bottom);

    &.sheet-has-footer {
      padding-bottom: 0;
    }
  }

  .sheet-close {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
    padding: 18px 12px 12px 12px;
    color: $control-color;
    cursor: pointer;
    .app-icon {
      height: 24px;
      width: 24px;
      font-size: 24px;
    }
    &:active {
      color: rgba(255, 255, 255, 1);
    }
  }
}
