@import '../variables.scss';

.description {
  margin: 5px 10px 5px;
  white-space: pre-wrap;
}

.officialDescription {
  composes: description;
  overflow: auto;
}

.flavorText {
  composes: description;
  font-style: italic;
  overflow: auto;
}

.wishListLabel {
  font-weight: bold;
}
.wishListTextContent {
  font-style: italic;
}
.loreLink {
  white-space: nowrap;
  img {
    vertical-align: text-bottom;
    margin-right: 0.2em;
  }
}
