@import '../variables.scss';

.statName {
  grid-column: 1;
  text-align: right;
  margin-right: 8px;
}

// The numeric value of the stat
.value {
  grid-column: 2;
  text-align: right !important;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
  > svg {
    vertical-align: middle;
    margin-left: 4px;
  }
}

.icon {
  margin-left: 4px;
  img {
    height: 12px;
    width: 12px;
    vertical-align: bottom;
    filter: drop-shadow(0 0 0 white);
  }
}

// Stat bars
.statBar {
  margin-left: 8px;
  grid-column: -2 / -1;
}
.barContainer {
  background-color: #333;
  display: flex;
  width: 100%;
  height: 100%;
}
.barInner {
  display: block;
  height: 100%;
  float: left;
  line-height: 20px;
  background-color: #555;
  background-color: white;
  color: black;
}

.qualitySummary {
  grid-column: 2 / -1;
  a {
    margin-left: 4px;
  }
}

.quality {
  display: none;
  margin-left: 4px;
  :global(.itemQuality) & {
    display: inline;
  }
}

// Colors for the stat bars
.masterworkStatBar {
  background-color: $orange;
}
.moddedStatBar {
  background-color: $blue;
  & + .moddedStatBar {
    background-color: adjust-color($blue, $lightness: -10);
    & + .moddedStatBar {
      background-color: adjust-color($blue, $lightness: -20);
    }
  }
}

.masterworked {
  color: $orange;
  .statName {
    font-weight: bold;
  }
}

.modded {
  color: $blue;
  .statName {
    font-weight: bold;
  }
}

.negativeModded {
  color: $red;
  .statName {
    font-weight: bold;
  }
}

.totalRow {
  padding-top: 4px;
}
.totalRow.value {
  border-top: 1px solid white;
  padding-top: 3px;
}
// Total stat breakdown
.totalStatDetailed {
  margin-left: 8px;
  grid-column: -2 / -1;
}

.totalStatModded {
  color: $blue;
}

.totalStatNegativeModded {
  color: $red;
}

.totalStatMasterwork {
  color: $orange;
}

.smallStatToggle {
  grid-column: -3 / -1;
  margin-left: 4px;
  img {
    height: 12px !important;
    width: 12px !important;
    vertical-align: bottom;
  }
}
