@import '../variables.scss';

.badge {
  margin-top: -1 * $item-border-width;
  background-color: #ddd;
  color: black;
  height: calc(#{$badge-height});
  font-size: calc(#{$badge-font-size});
  width: 100%;
  display: flex;
  text-align: right;
  box-sizing: border-box;
  padding: 0 2px;
  white-space: pre;
  align-items: center;
  justify-content: flex-end;
  line-height: calc(#{$badge-height});
  overflow: hidden;

  /* this width keeps the span aligned right (short text appears on right side of polaroid)
     but if the text is too long, it will overflow(hidden) off the right side instead of left. */
  span {
    max-width: 100%;
    max-height: 100%;
  }
}

.engram {
  width: fit-content;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 4px;
}

.wishlistRoll {
  display: flex;
  flex: 1;
}

.quality {
  display: none;
  margin-right: auto;

  :global(.itemQuality) & {
    display: block;
    padding: 0 2px;
    margin-left: -2px;
  }

  :global(.app-icon) {
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.8));
  }
}

.fullstack {
  font-weight: bold;
  color: #f2721b;
}

.capped {
  background-color: $gold;
}

.masterwork {
  background-color: #eade8b;
}

.energyCapacity {
  display: flex;
  margin-right: auto;
  justify-self: flex-start;
  align-items: center;
  font-weight: bold;
  font-size: calc((9 / 50) * var(--item-size)); // 9px at default 50px item size
  padding-right: 2px;
}

/* these are my calculations for colors that would look ok as text badges . not in use this moment.*/
.solar {
  color: #f0631e;
}
.arc {
  /* real arc: #79bbe8 rgb(121, 187, 232) - badgeinfo arc icon: #2b81bb rgb(43, 129, 187)*/
  /* #529ed1 rgb(82, 158, 209) */
  color: #529ed1;
}
.void {
  /* real void: #a371c2 rgb(163, 113, 194) - badgeinfo void icon: #804cce rgb(128, 76, 206) */
  /* #915ec8 rgb(145, 94, 200) */
  color: #915ec8;
}

.lightBackgroundElement {
  filter: brightness(75%) saturate(200%);
  background-color: transparent !important;
}

.energyCapacityIcon {
  composes: lightBackgroundElement;
  margin-right: 0;
  margin-left: 1px;
}
