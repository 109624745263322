.inlineSvg {
  /* destiny-icons repo is a fickle thing */
  height: 1.3em;
  width: auto;
  vertical-align: text-bottom;
  filter: invert(1);
  margin: 0 0.25em;
  &:first-child {
    margin-left: 0;
  }
}
