@import '../variables.scss';

#loadout-drawer.compare {
  padding-left: 10px;
  padding-top: 8px;
  @include max-sheet-height;
}

.compare-bucket {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .fixed-left {
    border-left: none;
  }
  .compare-stat-label {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 4px;
    padding-left: 0;
  }
  .talent-grid {
    padding: 4px 2px 0 2px;
    box-sizing: border-box;
    width: 150px;
    height: auto;
  }
}

.compare-item-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    padding: 0;
  }
}

.compare-initial-item {
  color: $orange;
  font-weight: bold;
}

.compare-options {
  display: flex;
  flex-wrap: wrap;
  .dim-button {
    margin-bottom: 4px;
    &:nth-last-child(n + 2) {
      margin-right: 3px;
    }
  }
}

.organizer-link {
  margin-left: auto;
  @include phone-portrait {
    margin-left: 0;
  }
}

.compare-items {
  flex: 1;
  white-space: nowrap;
  overflow-x: scroll;
  touch-action: pan-x;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  contain: content;
}

.compare-item {
  border-left: 1px solid rgba(245, 245, 245, 0.25);

  // targets the presstip wrapper
  & > .itemAside {
    float: right;
    padding: 0;
    margin-right: 4px;
    z-index: 10;
  }
  > div {
    padding-left: 4px;
  }
  .highlight {
    background-color: rgba(128, 128, 128, 0.25);
    cursor: pointer;
  }
  .item-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    margin-top: 2px;
    margin-bottom: 2px;

    .elemental {
      vertical-align: bottom;
      margin: 0;
    }
  }
  .sorted {
    color: $orange;
  }
  .close {
    position: static;
    float: right;
    margin: 1px 4px;
    padding-left: 0px;
  }
  .range {
    font-size: 0.9em;
  }
  .spacer {
    height: 46px;
  }

  span > .app-icon,
  .item-name .app-icon {
    font-size: 8px;
    margin-right: 2px;
    vertical-align: initial;
  }

  .tip-text {
    display: none;
  }

  .sockets > * {
    margin-bottom: 8px;
  }

  .socket-container {
    img {
      width: 26px;
      height: 26px;
    }
  }

  .item-sockets {
    flex-wrap: nowrap;
  }
  .item-socket {
    margin-right: 2px;
    &:last-child {
      margin-right: 0;
    }
  }

  .item-socket-category {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }

  .item-socket-category-Reusable .item-socket {
    border: none;
    padding: 0;
  }

  .item-details {
    clear: both;
    margin: 4px 4px 0 0;
  }

  .stat-recoil svg {
    margin-left: 5px;
    vertical-align: middle;
  }
}

@keyframes pop {
  to {
    transform: scale(1.5);
  }
}

.item-pop {
  animation: 0.25s linear 2 alternate pop;
  z-index: 1;
}

.horizontal {
  display: inline-block;
  margin-right: 5px;
  cursor: default;
  input {
    vertical-align: middle;
  }
}
