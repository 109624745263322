@import '../variables.scss';

.item-sockets {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sockets {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
}

// TODO: different styles for mods and perks
.item-socket-category {
  margin-bottom: 4px;
  margin-right: 16px;
  &:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }
}

.item-socket-category-name {
  text-transform: uppercase;
  margin-bottom: 2px;
  display: flex;
}

.item-socket {
  margin-right: 4px;
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-right: 0;
  }

  .disabled {
    opacity: 0.4;
  }

  .item-socket-category-Reusable & {
    border-right: 0.5px solid #444;
    padding-right: 4px;

    &:last-child {
      border: none;
      padding-right: 0;
      margin-right: 0;
    }
  }

  &.hasMenu {
    cursor: pointer;
    &:hover {
      outline: 1px solid $orange;
    }
  }
}

.plug-objectives {
  margin: 4px 0;
  padding: 8px;
  background-color: #333;
  border-radius: 4px;
}

.plug-stats {
  display: grid;
  grid-template: auto / auto 1fr;
  grid-column-gap: 4px;
  margin-top: 4px;
  > div {
    &:nth-child(2n + 1) {
      font-weight: bold;
      justify-self: end;
    }
  }
  img {
    vertical-align: bottom;
    margin-right: 2px;
  }
}

.category-name {
  display: inline-block;
}

.best-rated-key {
  text-transform: none !important;
  padding-left: 20px;
}

.best-rated-tip {
  font-size: 10px;
  margin-top: 6px;
}

.thumbs-up {
  background: #ddd;
  border-radius: 50%;
  color: #0b486b;
  padding: 3px;
  font-size: 8px !important;
  .socket-container & {
    position: absolute;
    top: -6px;
    right: -8px;
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.9));
  }
}

.socket-container {
  position: relative;
  display: flex;
  flex-direction: row;

  .item-socket-category-Consumable & {
    border: 1px solid #888;
  }

  img {
    -webkit-touch-callout: none;
    width: calc(32 / 50 * var(--item-size));
    height: calc(32 / 50 * var(--item-size));
    display: block;
  }
}

.item-socket-category-Reusable .socket-container.notIntrinsic {
  border: 1px solid #888;
  border-radius: 50%;
  background-color: #4887ba;
  padding: calc(2 / 50 * var(--item-size));
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }

  img {
    border: none;
    width: calc(24 / 50 * var(--item-size));
    height: calc(24 / 50 * var(--item-size));
  }

  @include phone-portrait {
    padding: 2px;
    img {
      width: 32px;
      height: 32px;
    }
  }

  &.notChosen {
    background-color: transparent;
  }

  .compare & {
    &.selectable {
      cursor: pointer;
    }

    &.selected {
      background-color: #48ba7bbb;
    }

    &.notSelected {
      background-color: #4887ba80;
    }
  }
}
