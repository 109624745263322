.stopOptionButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  .sessionoption {
    padding: 0;
    display: flex;
    border: none;
    color: #fff;
    cursor: pointer;
    margin: 4px;
    &.continue {
      background-color: #0d8109;
      span.icon {
        background-color: #0a5007;
      }
    }
    span.icon {
      margin: auto;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #b3060f;
    }
    span.text {
      padding: 8px;
      display: flex;
      white-space: nowrap;
      justify-content: center;
      align-items: center;
    }
  }
}
