.category {
  display: block;
  margin-right: 16px;
}

.categoryNameContainer {
  display: flex;
}

.categoryName {
  flex-grow: 1;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
  width: 0;
}

.mods {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: fit-content;

  > * {
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }
}
