.order-customer-note {
  display: block;
  width: 100%;
  border-bottom: 1px solid #686868;
  margin: 10px 0;
  padding-bottom: 15px;

  .customer-note-title {
    display: block;
    color: #ffffff;
    font-size: 18px;
    margin: 0;
    margin-bottom: 5px;
    font-weight: 500;
  }

  .customer-note {
    color: #ffffff;
  }
}
