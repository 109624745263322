.itemTagSelector {
  cursor: default;
  button {
    min-width: 10em;
    width: 100%;
  }
}

.item {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  > *:nth-child(2) {
    flex: 1;
    text-align: left;
  }

  :global(.app-icon) {
    margin-top: 1px;
    margin-right: 8px;
  }
}

.null {
  composes: app-icon from global;
  display: flex !important;
  align-items: center;
  justify-content: center;
  &::before {
    content: '';
    display: block;
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: white;
  }
}

.keyHelp {
  opacity: 0.7;
  button & {
    display: none;
  }
}

.minimized {
  button {
    padding: 0;
    background: none;
    min-width: auto;
  }
  button .item > *:nth-child(2) {
    display: none !important;
  }
  button > *:nth-child(2) {
    display: none !important;
  }
}
