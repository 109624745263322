@import '../variables.scss';

.notifications-container {
  position: fixed;
  @include below-header;
  right: 0;
  z-index: 1000;
  padding: 8px;
  box-sizing: border-box;
  width: 100%;
  backface-visibility: hidden;
  pointer-events: none;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include phone-portrait {
    bottom: 0;
    flex-direction: column-reverse;
    padding-bottom: calc(44px + env(safe-area-inset-bottom));

    @media all and (display-mode: standalone) {
      padding-bottom: env(safe-area-inset-bottom);
    }
  }
}
