@import '../variables.scss';

.bungie-alert {
  margin: 1em 0;
  padding: 1em;
  border: 2px solid rgba(255, 255, 255, 0.5);
  a {
    color: white;
    font-size: 12px;
  }
}

.bungie-alert-error {
  background-color: $red !important;
}
.bungie-alert-info {
  background-color: #2f96b4 !important;
}
.bungie-alert-warn {
  background-color: #f89406 !important;
}
