.perk {
  > img,
  > * > img {
    height: var(--item-size);
    width: var(--item-size);
  }
  > * {
    margin-right: 8px;
  }

  border: 1px solid #666;
  padding: 8px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  height: 100%;
  box-sizing: border-box;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.perkInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.perkTitle {
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 4px;
}

.unselectable {
  opacity: 0.5;
}

.lockedPerk {
  border-color: orange;
}

.plugStats {
  display: grid;
  grid-template: auto / auto 1fr;
  grid-column-gap: 4px;
  margin-top: 4px;
  > div {
    &:nth-child(2n + 1) {
      font-weight: bold;
      justify-self: end;
    }
  }
  img {
    vertical-align: bottom;
    margin-right: 2px;
  }
}

.iconContainer {
  height: fit-content;
}

.requirement {
  margin-top: 8px;
  font-style: italic;
}
