.textBlockWrapper {
  position: relative;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: inset 0 -31px 20px -20px black;
  }
  &.open:hover {
    cursor: default;
  }
  &.open::after {
    display: none;
  }
}
