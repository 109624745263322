.junkIcon {
  color: hsl(0, 100%, 30%);
}
.keepIcon {
  color: hsl(125, 100%, 30%);
}
.tagIcon {
  position: relative;
  margin: -1px;
  bottom: 5px;
  height: 8px;
}
