.offline-manifest-loader-main-div {
  width: 100%;
  height: 15px;
  background-color: #252525;
  border-radius: 50px;
  position: relative;
  border: 2px solid #777777;
  .offline-manifest-loader {
    display: block;
    height: 100%;
    background-color: #2c3e50;
    background-position-x: -219px;
    background-size: 200% 100%;
    background-image: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 40px,
      #ff6800 40px,
      #ff6800 80px
    );

    animation: slide-offline-loader 1s linear infinite;
    border-radius: 10px;
    will-change: background-position;
    transition: width 100ms linear;
  }
  .offline-manifest-loader-text {
    position: absolute;
    display: block;
    top: 125%;
    left: 50%;
    color: #f66800;
    transform: translateX(-50%);
    font-size: 16px;
    font-weight: bold;
  }

  @keyframes slide-offline-loader {
    from {
      background-position-x: -219px;
    }
    to {
      background-position-x: -107px;
    }
  }
}
